























import { Component, Vue } from 'vue-property-decorator';
import { dispatchUpdateLocalidade } from '@/store/localidade/actions';
import { consultarLocalidade } from '@/store/localidade/getters';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import cacheDominiosEstaticos from '@/store/localidade/cache';
import LocalidadeForm from '@/components/forms/LocalidadeForm.vue';
import { ILocalidadeCreateUpdate } from '@/interfaces/localidade';

@Component({
  components: {
    LocalidadeForm,
  },
})
export default class AlterarLocalidade extends Vue {
  public valid = true;
  public localidadeConsultada;
  public localidadeParaAtualizar: ILocalidadeCreateUpdate = {
    id_origem_localidade: 0,
    id_tipo_localidade: 0,
    id_subprefeitura: 0,
    id_distrito: 0,
    id_bairro: 0,
    id_regiao: 0,
    id_tipo_logradouro: 0,
    id_responsavel: 0,
    id_status_implantacao: 0,
    id_tipo_publicidade: 0,
    codigo_localidade: '',
    nome: '',
    referencia: '',
    logradouro: '',
    numero_logradouro: 0,
    complemento_logradouro: '',
    cep: '',
    latitude: 0.0,
    longitude: 0.0,
  };

  public async mounted() {
    await cacheDominiosEstaticos(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    this.localidadeConsultada = consultarLocalidade(this.$store)(+this.$router.currentRoute.params.id);
    this.localidadeParaAtualizar = {
      id_origem_localidade: this.localidadeConsultada.origem_localidade.id,
      id_tipo_localidade: this.localidadeConsultada.tipo_localidade.id,
      id_subprefeitura: this.localidadeConsultada.subprefeitura.id,
      id_distrito: this.localidadeConsultada.distrito.id,
      id_bairro: this.localidadeConsultada.bairro.id,
      id_regiao: this.localidadeConsultada.regiao.id,
      id_tipo_logradouro: this.localidadeConsultada.tipo_logradouro.id,
      id_responsavel: this.localidadeConsultada.responsavel.id,
      id_status_implantacao: this.localidadeConsultada.status_implantacao.id,
      id_tipo_publicidade: this.localidadeConsultada.tipo_publicidade.id,
      codigo_localidade: this.localidadeConsultada.codigo_localidade,
      nome: this.localidadeConsultada.nome,
      referencia: this.localidadeConsultada.referencia,
      logradouro: this.localidadeConsultada.logradouro,
      numero_logradouro: this.localidadeConsultada.numero_logradouro,
      complemento_logradouro: this.localidadeConsultada.complemento_logradouro,
      cep: this.localidadeConsultada.cep,
      latitude: this.localidadeConsultada.latitude,
      longitude: this.localidadeConsultada.longitude,
    };
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const atualizou = await dispatchUpdateLocalidade(this.$store, {
        id: this.localidadeConsultada.id,
        localidade: this.localidadeParaAtualizar,
      });
      if (atualizou) {
        dispatchGetLocalidades(this.$store, 0);
        this.$router.push('/main/localidade/listar');
      }
    }
  }
}
